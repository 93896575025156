import Vue from "vue";
import { API } from "../../services/API"
//import { maskCPFCNPJ, maskTELEFONE, CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../services/funcoes";

function initialState() {

  return {
    dados                         : [],
    acao                          : "C", // E=Edicao, I=Inclusão, C=Consulta
    pessoa_edicao                 : {},  // Pessoa Selecionada para Edição
    pessoa_conjuge_edicao         : {},
    pessoa_selecionado            : null, 
    pessoa_conjuge_selecionado    : null, 
    preenche_search_pessoa_cadastrada        : '',

    dialog_cadastro                : false,
    dialog_cadastro_conjuge        : false,
    dialog_cadastro_mobile         : false,
    dialog_cadastro_juridica_mobile: false,
    search                         : null,
    currentPage                    : 1,
    count_dados                    : null,
    filtro                         : null,
    pessoa_cadastro_mobile         : null,

    dialogConjuge                 : false,
    dialogPessoa                  : false,
    dialogConjugeExcluir          : false,
    dialogEnderecosCadastro       : false,
    dialogEnderecosExclusao       : false,
    dialogDocumentacaoCadastro    : false,
    dialogDocumentacaoExclusao    : false,
    dialogContatosCadastro        : false,
    dialogContatosExclusao        : false,
    dialogDadosBancariosCadastro  : false,
    dialogDadosBancariosExclusao  : false,
    dialogSociosCadastro          : false,
    dialogSociosExclusao          : false,
    // dialog_excluir                : false,  
    // dialog_excluir_tipo           : false,  
    // dialog_excluir_registro       : false,  

    abas: [
      ['mdi-view-dashboard-outline', 'Dados Básicos', 5],
      ['mdi-text-box-outline', 'Documentos', 0],
      // ['mdi-account-outline', 'Privilégios', 0],
    ],
        
  }
}

var vm_store_Pessoa = new Vue({

  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },

    // Tela de Consulta ====================================================================
    async PessoaGet(p_params) {
      // var lo_Params;
      // if (p_codUsuario)
      //     lo_Params = {cod_usuario : p_codUsuario}
      // else if (p_filtro)
      //     lo_Params = {filtro : p_filtro}
      // else
      //     lo_Params = {}
      const resposta = await API.get("pessoa", {
                                                  params: {
                                                      ...p_params
                                                  },
                                                  }
                                      );
      if (resposta) {
        this.dados = [];
        if (resposta.data.result != 'Não possui dados') {
          //if (p_params && p_params.cod_pessoa) {
            this.dados = resposta.data.result.rows
            return resposta.data.result
          //}
          //else {
          //  this.dados   = resposta.data.result.rows;
          //  this.paginas = resposta.data.result.paginas;
          //}
        }
      }
    },

    async PessoaEnderecoPost(body) {
      // console.log('body', body);
      // return
      const resp = await API.post(`/pessoa_endereco`, JSON.stringify(body));
      // console.log('resp', resp);

      const { data } = resp;
      return data;
    },

    async PessoaSocioPost(body) {
      const resp = await API.post(`/pessoa_socio`, JSON.stringify(body));
      const { data } = resp;
      return data;
    },


    async PessoaPost(body) {
      const resp = await API.post(`/pessoa`, JSON.stringify(body));
      const { data } = resp;
      return data;
    },
      
    async PessoaPut(body) {
      const resp = await API.put(`/pessoa/${body.cod_pessoa}`, JSON.stringify(body))
      const { data } = resp;
      return data;
    },

    async PessoaDelete(body) {
      const resp = await API.delete(`/pessoa/${body.cod_pessoa}`)
      const { data } = resp;
      if (resp.status == 200) 
        return data.result;
      else
        return data;
    },

    // Tela de Documentos ==================================================================
    async PessoaDocumentoGet(p_params) {
      const resposta = await API.get("pessoa_documento", {
                                                  params: {
                                                    ...p_params
                                                  },
                                                }
                                      );
                                      // console.log(p_params, resposta)
                                     
      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          return resposta.data.result
        }
      }
    },

    async PessoaDocumentoDelete(body) {
      // console.log(body);
      // return
      const resposta = await API.delete(`ged_tipo_documento/${body.cod_ged}`)

      const { data } = resposta;
      if (resposta.status == 200) 
        return data.result;
      else
        return data;
    
    },

    async PessoaDocumentoPost(body) {
      const resp = await API.post(`/pessoa_documento`, JSON.stringify(body));
    
      const { data } = resp;
      if (resp.status == 200) 
        return data.result;
      else
        return data;
    },

    
  },

})

export default vm_store_Pessoa
